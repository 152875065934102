import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { SnackBar } from '@components';
import { map } from 'rxjs';
import { BasketService } from '../services';

export const validCheckoutGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const basketService = inject(BasketService);
  const router = inject(Router);
  const snackBar = inject(SnackBar);

  const paymentIntentId = route.queryParams['payment_intent'];
  if (paymentIntentId) {
    return basketService.validateCheckout().pipe(
      map((response) => {
        if (response.success) {
          basketService.cacheCheckoutBooking(response.order);
          return true;
        } else {
          snackBar.error('Unable to complete the transaction.  Please try again.');
          return router.createUrlTree(['/', 'checkout']);
        }
      }),
    );
  } else {
    return router.createUrlTree(['/']);
  }
};

import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent, MastheadComponent, SnackBarComponent } from '@components';

@Component({
  selector: 'app-root',
  standalone: true,
  providers: [],
  imports: [RouterOutlet, MastheadComponent, FooterComponent, SnackBarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {}

import { Injectable, computed } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  deviceId = computed(() => {
    const deviceIdKey = 'deviceId';
    let deviceId = localStorage.getItem(deviceIdKey);

    if (!deviceId) {
      deviceId = btoa(`${crypto.randomUUID()}/${new Date().toISOString()}`);
      localStorage.setItem(deviceIdKey, deviceId);
    }

    return deviceId;
  });
}
